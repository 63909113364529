<template>
  <Layout class="GoodsManagement">
    <BHeader>
      <el-button
        type="primary"
        @click="handleGOReleaseProduct"
      >
        发布商品
      </el-button>
    </BHeader>
    <div class="nav-bar">
      <el-tabs
        v-model="formData.type"
        type="card"
      >
        <el-tab-pane
          label="全部"
          name="all"
        />
      </el-tabs>
    </div>
    <div class="content">
      <el-table
        v-loading="dataInfo.loading"
        :data="dataInfo.list"
        style="width: 100%"
      >
        <el-table-column
          label="商品信息"
          width="300"
        >
          <template v-slot="{row}">
            <div class="block-info">
              <el-image
                class="goods-cover"
                fit="contain"
                :src="row.imageHorizontal"
                lazy
              />
              <span>{{ row.goodsName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="原价(美元)"
        >
          <template v-slot="{row}">
            {{ row.goodsPrice | priceFilter }}
          </template>
        </el-table-column>
        <el-table-column
          label="运费(美元)"
        >
          <template v-slot="{row}">
            {{ row.shippingPrice | priceFilter }}
          </template>
        </el-table-column>
        <el-table-column
          prop="stock"
          label="库存"
        >
          <template v-slot="{row}">
            {{ row.stockCount | stockFilter }}
          </template>
        </el-table-column>
        <el-table-column
          prop="salesVolume"
          label="销量"
        >
          <template v-slot="{row}">
            {{ row.salesCount | stockFilter }}
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
        >
          <template v-slot="{row}">
            {{ (goodsStateMap[row.goodsStatus] || {}).name || '未知' }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="260px"
        >
          <template v-slot="{row}">
            <el-button
              type="text"
              @click="handleEditGoods(row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              :loading="row.statusLoading"
              @click="handleChangeStatus(row)"
            >
              {{ row.goodsStatus === 1 ? '下架' : '上架' }}
            </el-button>
            <el-button
              :loading="row.recommendLoading"
              type="text"
            >
              <el-switch
                :value="row.recommend"
                :active-value="1"
                :inactive-value="2"
                inactive-text="编辑推荐"
                @change="handleChangeRecommend(row)"
              />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="dataInfo.total"
        :page.sync="formData.pageNum"
        :limit.sync="formData.pageSize"
        @pagination="getMallGoodsList"
      />
    </div>
  </Layout>
</template>

<script>
import { getMallGoodsList, updateMallGoodsStatus, updateGoodsRecommend } from '@/api/mall-server'
import { goodsStateMap } from '@/utils/selectOptions'

export default {
  name: 'GoodsManagement',
  filters: {
    priceFilter (price) {
      if (!price) return '/'
      return '$' + price.toFixed(2)
    },
    stockFilter (stock) {
      if (!stock) return '/'
      return stock + '件'
    }
  },
  data () {
    return {
      goodsStateMap,
      formData: {
        type: 'all',
        pageNum: 1,
        pageSize: 20
      },
      dataInfo: {
        loading: false,
        total: 0,
        list: []
      }
    }
  },
  created () {
    this.getMallGoodsList()
  },
  methods: {
    handleGOReleaseProduct () {
      this.$router.push({
        name: 'ReleaseProduct'
      })
    },
    handleEditGoods (row) {
      this.$router.push({
        name: 'EditProduct',
        query: {
          id: row.id
        }
      })
    },
    getMallGoodsList () {
      this.dataInfo.loading = true
      getMallGoodsList(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.dataInfo.list = res.data.list
            this.dataInfo.total = res.data.total
          }
        })
        .finally(() => {
          this.dataInfo.loading = false
        })
    },
    handleChangeStatus (row) {
      const TIPS = row.goodsStatus === 2 ? '此操作将上架商品， 是否继续?' : '此操作将下架商品， 是否继续?'
      const NEXT_STATUS = row.goodsStatus === 2 ? 1 : 2
      this.$confirm(TIPS, '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'statusLoading', true)
          return updateMallGoodsStatus({
            id: row.id,
            status: NEXT_STATUS
          })
        })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('更新上架状态成功')
            this.$set(row, 'goodsStatus', NEXT_STATUS)
          }
        })
        .finally(() => {
          this.$set(row, 'statusLoading', false)
        })
    },
    handleChangeRecommend (row) {
      this.$set(row, 'recommendLoading', true)
      const NEXT_STATUS = row.recommend === 1 ? 2 : 1
      updateGoodsRecommend({
        id: row.id,
        status: NEXT_STATUS
      }).then(res => {
        if (res.code === 200) {
          this.$set(row, 'recommend', NEXT_STATUS)
        }
      })
        .finally(() => {
          this.$set(row, 'recommendLoading', false)
        })
    }
  }
}
</script>

<style lang="less">
  .GoodsManagement{
    .content{
      .el-switch__label{
        color: @primary;
      }
    }
  }
</style>
<style scoped lang="less" >
  .nav-bar{}
  .content{
    .block-info{
      display: flex;
      align-items: center;
      .goods-cover{
        width: 80px;
        height: 80px;
        flex-shrink: 0;
        margin-right: 10px;
      }
    }
  }
 </style>
